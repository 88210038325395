import {
    FETCH_LEARNINGPLAN,
    EMPTY_LEARNINGPLAN,
    START_LEARNINGPLAN,
    STARTED_LEARNINGPLAN,
    RESET_LEARNINGPLAN,
    NAVIGATE_PARENTFLOW,
    SIGNAL_LEARNINGPLAN,
} from '../actions/learningplan';
import {setVisibility} from '../util/nodeVisibility';

const app = (state = {}, action) => {
    const {type, payload} = action;

    switch (type) {
        case `${FETCH_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${FETCH_LEARNINGPLAN}_FULFILLED`: {
            const {sequence, trackingRecords, isTester} = payload;

            setVisibility(sequence, trackingRecords, isTester);
            return {
                ...state,
                ...payload,
                started: false,
                isLoading: false,
            };
        }
        case `${FETCH_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case EMPTY_LEARNINGPLAN: {
            return {
                ...state,
                instanceId: null,
                targetId: null,
                workflowStatus: 'notStarted',
                started: false,
                isLoading: false,
            };
        }

        case `${START_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${START_LEARNINGPLAN}_FULFILLED`: {
            const {sequence, trackingRecords, isTester} = payload;

            setVisibility(sequence, trackingRecords, isTester);
            return {
                ...state,
                ...payload,
                started: true,
                isLoading: false,
            };
        }
        case `${START_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                started: false,
            };
        }

        case STARTED_LEARNINGPLAN: {
            return {
                ...state,
                started: false,
            };
        }

        case `${RESET_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${RESET_LEARNINGPLAN}_FULFILLED`: {
            const {sequence, trackingRecords, isTester} = payload;

            setVisibility(sequence, trackingRecords, isTester);
            return {
                ...state,
                ...payload,
                started: true,
                isLoading: false,
            };
        }
        case `${RESET_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${NAVIGATE_PARENTFLOW}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${NAVIGATE_PARENTFLOW}_FULFILLED`: {
            const {sequence, trackingRecords, isTester} = payload;

            setVisibility(sequence, trackingRecords, isTester);
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        }
        case `${NAVIGATE_PARENTFLOW}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case `${SIGNAL_LEARNINGPLAN}_PENDING`: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case `${SIGNAL_LEARNINGPLAN}_FULFILLED`: {
            const {trackingRecords} = payload;
            const sequence = JSON.parse(JSON.stringify(state.sequence));

            setVisibility(sequence, trackingRecords, state.isTester);
            return {
                ...state,
                ...payload,
                sequence: sequence,
                started: false,
                isLoading: false,
            };
        }
        case `${SIGNAL_LEARNINGPLAN}_REJECTED`: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export default app;
