/* eslint-disable prettier/prettier */
export const FETCH_LEARNINGPLAN = 'FETCH_LEARNINGPLAN';
export const EMPTY_LEARNINGPLAN = 'EMPTY_LEARNINGPLAN';
export const START_LEARNINGPLAN = 'START_LEARNINGPLAN';
export const STARTED_LEARNINGPLAN = 'STARTED_LEARNINGPLAN';
export const RESET_LEARNINGPLAN = 'RESET_LEARNINGPLAN';
export const SIGNAL_LEARNINGPLAN = 'SIGNAL_LEARNINGPLAN';
export const NAVIGATE_PARENTFLOW = 'NAVIGATE_PARENTFLOW';

const getHeaders = (user) => {
    return {
        Authorization: `Bearer ${user.access_token}`,
        'Content-Type': 'application/json',
    };
};

export const fetchLearningPlan = (workflow) => async (dispatch, getState) => {
    const {minervaSettings, oidc, learningPlan} = getState();
    const {portalApiUrl, catalogApiUrl, portalIdentifier} = minervaSettings;
    const {instanceId, newStart} = workflow;

    if (!portalApiUrl) return;

    if (!instanceId || instanceId === '') {
        dispatch({
            type: EMPTY_LEARNINGPLAN,
        });
        return;
    }

    let instanceStack = learningPlan.instanceStack || [instanceId];

    if (newStart) {
        // fresh starts always start with a new instanceStack
        instanceStack = [instanceId];
    }

    if (instanceStack[0] !== instanceId) {
        instanceStack.push(instanceId);
    }

    dispatch({
        type: FETCH_LEARNINGPLAN,
        async payload() {
            const data = await loadWorkFlow(portalApiUrl, instanceId, oidc.user);

            const catalogItem = await fetch(
                `${catalogApiUrl}/api/catalogItem/${portalIdentifier}/${data.targetId}`,
                {
                    headers: getHeaders(oidc.user),
                },
            );
            const catalogData = await catalogItem.json();

            return {
                ...data,
                openScormInNewWindow: catalogData.openScormInNewWindow,
                instanceStack,
                instanceId: workflow.instanceId,
            };
        },
    });
};

export const startOrResumeLearningPlan = ({targetId}) => async (dispatch, getState) => {
    const {minervaSettings, oidc, learningPlan, learningPlanInfo} = getState();
    const {portalApiUrl} = minervaSettings;
    const {user} = oidc;

    dispatch({
        type: START_LEARNINGPLAN,
        async payload() {
            const notStarted = learningPlanInfo.progress.toLowerCase() === 'notstarted';

            await fetch(`${portalApiUrl}/api/1.0/learningplan`, {
                method: 'POST',
                headers: getHeaders(user),
                body: JSON.stringify({
                    learningPlanId: targetId,
                    idString: user.profile.idString,
                }),
            });

            if (notStarted) {
                const params = new URLSearchParams({
                    targetId: targetId,
                    idString: user.profile.idString,
                });

                const res = await fetch(
                    `${portalApiUrl}/api/1.0/learningplan/info?${params}`,
                    {
                        headers: getHeaders(user),
                    }
                );

                const info = await res.json();
                const data = await loadWorkFlow(portalApiUrl, info.instanceId, user);

                return {
                    ...data,
                    instanceStack: [ info.instanceId],
                    instanceId:  info.instanceId,
                }
            }

            if (learningPlan.instanceId) {
                const data = await loadWorkFlow(portalApiUrl, learningPlan.instanceId, user);

                return data;
            }

            return {};
        },
    });
};

export const resetLearningPlan = ({targetId}) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const {portalApiUrl} = minervaSettings;
    const {user} = oidc;

    await dispatch({
        type: RESET_LEARNINGPLAN,
        async payload() {
            let res = await fetch(`${portalApiUrl}/api/1.0/learningplan/reenroll`, {
                method: 'POST',
                headers: getHeaders(user),
                body: JSON.stringify({
                    learningPlanId: targetId,
                    idString: user.profile.idString,
                }),
            });

            const response = await res.json();
            const data = await loadWorkFlow(portalApiUrl, response.instanceId, user);

            return {
                ...data,
                instanceStack: [response.instanceId],
                instanceId: response.instanceId,
            }
        },
    });
};

export const navigateParentFlow = () => async (dispatch, getState) => {
    const {minervaSettings, oidc, learningPlan} = getState();
    const {portalApiUrl} = minervaSettings;
    const {user} = oidc;
    const {instanceStack} = learningPlan;

    if (!portalApiUrl) return;

    dispatch({
        type: NAVIGATE_PARENTFLOW,
        async payload() {
            instanceStack.pop();
            const newInstanceId = instanceStack[instanceStack.length - 1];
            const data = await loadWorkFlow(portalApiUrl, newInstanceId, user);

            return {
                ...data,
                instanceStack,
                instanceId: newInstanceId,
            };
        },
    });
};

export const startedLearningPlan = () => (dispatch, getState) => {
    dispatch({ type: STARTED_LEARNINGPLAN })
}

export const signal = (workflowInstanceId, reload) => async (dispatch, getState) => {
    const {minervaSettings, oidc} = getState();
    const {portalApiUrl} = minervaSettings;
    const {user} = oidc;

    await dispatch({
        type: SIGNAL_LEARNINGPLAN,
        async payload() {
            let res = await fetch(
                `${portalApiUrl}/api/1.0/workflow/signal?instanceId=${workflowInstanceId}`,
                {
                    method: 'POST',
                    body: {},
                    headers: getHeaders(user),
                },
            );

            let data = await res.json();

            if (reload) {
                const workflowData = await loadWorkFlow(portalApiUrl, workflowInstanceId, user);

                data = {...workflowData, ...data};
            }

            return {
                ...data,
            };
        },
    });
};

const loadWorkFlow = async (portalApiUrl, instanceId, user) => {
    const res = await fetch(
        `${portalApiUrl}/api/1.0/workflow/definition?instanceId=${instanceId}`,
        {
            headers: getHeaders(user),
        },
    );

    return await res.json();
}